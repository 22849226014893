import React from 'react'

import BlogPage from '../components/layouts/blog_page'
import TextLink from '../components/common/text_link'
import Em from '../components/common/emphasis'
import P from '../components/common/paragraph'

import META_IMAGE_SRC from '../images/sparkler.jpg'

export default ({ location }) => (
  <BlogPage
    location={location}
    metaImage={META_IMAGE_SRC}
    metaImageAlt="A sparkler buried in the ground"
  >
    <P>Hey; I added a blog to the site.</P>

    <P>
      It's lighter and cleaner than the bulk of the site at{' '}
      <TextLink href="/" text="unwttng.com" />, and will serve as a little place
      for me to update you on goings-on.
    </P>

    <P>
      I'm writing today's post to talk a little about how I intend to work here
      and elsewhere in this new year.
    </P>

    <P>
      As you'll know if you're a Patron, I make a promise not to charge for
      articles or guides written for the site more than once a month. In 2018 I
      want to solidify this promise in my own working practice by not actually
      aiming to <Em>write</Em> more than one guide a month. This will hopefully
      allow me to focus on just that one - there's always the danger of trying
      to get more than one long-form, researched and custom-coded piece out in a
      thirty day period and burning out. I'm also variously looking after a
      less-than-one-year-old baby and working a full time job, so I feel this is
      a realistic approach to take mentally.
    </P>

    <P>
      I like to imagine and create other projects too. There's a huge list of
      potential ideas, things to learn and digital gifts to make and the usual
      problems that come with such a list: I start lots of projects, I don't
      release or finish many of them, and they build on my cognitive load. With
      that in mind, I'm taking a similar approach in this area - one project a
      month, at most. Other ideas or temptations during that month can go on the
      pile to be considered for next month, but a one-month-one-project policy
      is now in effect.
    </P>

    <P>I'll let you know how it goes.</P>
  </BlogPage>
)
